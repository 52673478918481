<template>
  <div class="banner-area">
    <div class="banner-content p-10">
      <div class="flex items-center mb-4">
        <h2 class="banner-content__title text-lg font-bold ">배너 1</h2>
        <p class="ml-4">최소 1개 이상 등록 가능하며 순서대로 슬라이드 됩니다.</p>
      </div>
      <div class="wide-banners-area">
        <div v-for="(banner, index) in mainBannerData" class="flex items-end mb-5" :key="banner+index">
          <label
            class="
              border
              flex
              items-center
              justify-center
              cursor-pointer
              drag-container
              relative
            "
              style="width: 960px; height: 325px;"
              :for="`imageFilesInputEl_${index}`"
          >
            <input
                type="file"
                multiple
                name="productImageList"
                accept=".pdf,.jpg,.jpeg,.png"
                class="w-px h-px opacity-0 overflow-hidden absolute"
                :id="`imageFilesInputEl_${index}`"
                :ref="`imageFilesInputEl_${index}`"
                @change="(e) => handleChangeImageInput('main', index, e)"
            />
            <span class="banner-image"
                 :style="{
                backgroundImage: `url(${banner.imageUrl || '/images/default-img-blue.png'})`,
              }"></span>
            <span class="hidden justify-center items-center hover-banner-text">
              <IconBase
                  :width="48"
                  :height="48"
                  color="#ffffff"
                  viewBox="0 0 48 48"
              >
                <PictureIcon></PictureIcon>
              </IconBase>
            </span>
          </label>
          <div class="pl-4 wide-banner-text relative">
            <button v-if="!banner.bannerId" class="absolute w-5 h-5 top-0 right-0" @click="removeMainBannerItem(index)">
              <IconBase :width="20" :height="20">
                <CircleXIcon/>
              </IconBase>
            </button>
            <p class="text-sm text-gray-400">1920 * 650 사이즈의 JPG 파일로 업로드</p>
            <p class="text-sm text-black my-5 font-bold">Link URL</p>
            <input type="text" class="border border-black h-10 w-full px-4 text-sm admin-input" placeholder="/lot/10000832" v-model="banner.targetUrl">
            <p class="mt-5 text-sm text-gray-400 leading-normal">내부 URL일 경우 도메인을 제외한 URL 입력</p>
            <ul class="text-sm text-gray-400 leading-normal banner-notice-list">
              <li class="flex items-center">상품 : /lot/랏번호</li>
              <li class="flex items-center">셀러 : /seller/셀러번호</li>
              <li class="flex items-center">카테고리 : /category/2</li>
            </ul>
            <div class="flex justify-center mt-5">
              <template v-if="index > 0">
                <button
                    v-if="banner.bannerId"
                    class="
                      w-60
                      h-12
                      border border-black
                      shadow
                      flex
                      items-center
                      justify-center
                      mr-2
                      bg-gray-400
                      admin-button
                      text-white
                    "
                    @click="handlerDelete(banner.bannerId)"
                >
                  <IconBase color="#fff">
                    <RemoveIcon/>
                  </IconBase>
                  <span class="ml-2">삭제하기</span>
                </button>
              </template>

              <button
                  class="
                      w-60
                      h-12
                      border border-black
                      shadow
                      flex
                      items-center
                      justify-center
                      admin-button
                    "
                  @click="handleRegister('main', banner)"
              >
                <IconBase>
                  <CheckCircleIcon></CheckCircleIcon>
                </IconBase>
                <span class="ml-2">등록하기</span>
              </button>
            </div>
          </div>
        </div>
        <div class="flex justify-center mt-8 mb-8">
          <AddBanner class="cursor-pointer" @click="addMainBannerItem"/>
        </div>
      </div>
      <div class="grid grid-cols-3 gap-24 pt-8 border-t border-gray-300">
        <div  v-for="(subBanner, index) in subBannerData" :key="subBanner+index" style="width: 400px;">
          <h2 class="banner-content__title text-lg font-bold mb-5">배너 {{ index+2 }}</h2>
          <label
            class="
            border
            flex
            items-center
            justify-center
            cursor-pointer
            drag-container
            relative
          "
            style="width: 400px; height: 325px;"
            :for="`imageFilesInputElSub_${index}`"
          >
            <input
                type="file"
                multiple
                name="productImageList"
                accept=".pdf,.jpg,.jpeg,.png"
                class="w-px h-px opacity-0 overflow-hidden absolute"
                :id="`imageFilesInputElSub_${index}`"
                :ref="`imageFilesInputElSub_${index}`"
                @change="(e) => handleChangeImageInput('sub', index, e)"
            />
            <span class="banner-image"
                 :style="{
                backgroundImage: `url(${subBanner.imageUrl})`,
              }"
            ></span>
            <span class="hidden justify-center items-center hover-banner-text">
              <IconBase
                  :width="48"
                  :height="48"
                  color="#ffffff"
                  viewBox="0 0 48 48"
              >
                <PictureIcon></PictureIcon>
              </IconBase>
            </span>
          </label>
          <div class="pt-4">
            <p class="text-sm text-gray-400">800 * 650 사이즈의 JPG 파일로 업로드</p>
            <p class="text-sm text-black my-5 font-bold">Link URL</p>
            <input type="text" class="border border-black h-10 w-full px-4 text-sm admin-input" placeholder="/lot/10000832" v-model="subBanner.targetUrl">
            <p class="mt-5 text-sm text-gray-400 leading-normal">내부 URL일 경우 도메인을 제외한 URL 입력</p>
            <ul class="text-sm text-gray-400 leading-normal banner-notice-list">
              <li class="flex items-center">상품 : /lot/랏번호</li>
              <li class="flex items-center">셀러 : /seller/셀러번호</li>
              <li class="flex items-center">카테고리 : /category/2</li>
            </ul>
            <button
                class="
                      w-60
                      h-12
                      border border-black
                      shadow
                      flex
                      items-center
                      justify-center
                      admin-button
                      mx-auto
                      mt-5
                      mb-6
                    "
                @click="handleRegister('sub', subBanner)"
            >
              <IconBase>
                <CheckCircleIcon></CheckCircleIcon>
              </IconBase>
              <span class="ml-2">등록하기</span>
            </button>
          </div>
        </div>
      </div>
      <div class="flex items-center pt-6 border-t border-gray-300">
        <h2 class="banner-content__title text-lg font-bold ">모바일 배너</h2>
        <p class="ml-4">최소 1개, 최대 3개까지 등록가능하며 순서대로 슬라이드 됩니다.</p>
      </div>
      <div class="flex pt-6 items-center">
        <div  v-for="(mobileBanner, index) in mobileBannerData" :key="mobileBanner+index" style="width: 360px; margin-right: 60px;">
          <label
            class="
              border
              flex
              items-center
              justify-center
              cursor-pointer
              drag-container
              relative
            "
            style="width: 360px; height: 240px;"
            :for="`imageFilesInputElMobile_${index}`"
          >
            <input
              type="file"
              multiple
              name="productImageList"
              accept=".pdf,.jpg,.jpeg,.png"
              class="w-px h-px opacity-0 overflow-hidden absolute"
              :id="`imageFilesInputElMobile_${index}`"
              :ref="`imageFilesInputElMobile_${index}`"
              @change="(e) => handleChangeImageInput('mobile', index, e)"
            />
            <span
              class="banner-image"
              :style="{
                backgroundImage: `url(${mobileBanner.imageUrl})`,
              }"
            ></span>
            <span class="hidden justify-center items-center hover-banner-text">
              <IconBase
                :width="48"
                :height="48"
                color="#ffffff"
                viewBox="0 0 48 48"
              >
                <PictureIcon></PictureIcon>
              </IconBase>
            </span>
          </label>
          <div class="pt-4">
            <p class="text-sm text-gray-400">640 * 480 사이즈의 JPG 파일로 업로드</p>
            <p class="text-sm text-black my-5 font-bold">Link URL</p>
            <input type="text" class="border border-black h-10 w-full px-4 text-sm admin-input" placeholder="/lot/10000832" v-model="mobileBanner.targetUrl">
            <p class="mt-5 text-sm text-gray-400 leading-normal">내부 URL일 경우 도메인을 제외한 URL 입력</p>
            <ul class="text-sm text-gray-400 leading-normal banner-notice-list">
              <li class="flex items-center">상품 : /lot/랏번호</li>
              <li class="flex items-center">셀러 : /seller/셀러번호</li>
              <li class="flex items-center">카테고리 : /category/2</li>
            </ul>
            <div class="flex justify-center mt-5">
              <template v-if="index > 0">
                <button
                  v-if="mobileBanner.bannerId"
                  class="
                    h-12
                    border border-black
                    shadow
                    flex
                    items-center
                    justify-center
                    mr-5
                    bg-gray-400
                    admin-button
                    sub-admin-button
                    text-white
                  "
                  @click="handlerDelete(mobileBanner.bannerId)"
                >
                  <IconBase color="#fff">
                    <RemoveIcon/>
                  </IconBase>
                  <span class="ml-2">삭제하기</span>
                </button>
              </template>
              <button
                class="
                  h-12
                  border border-black
                  shadow
                  flex
                  items-center
                  justify-center
                  admin-button
                  sub-admin-button
                "
                @click="handleRegister('mobile', mobileBanner)"
              >
                <IconBase>
                  <CheckCircleIcon></CheckCircleIcon>
                </IconBase>
                <span class="ml-2">등록하기</span>
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="mobileBannerData.length < 3"
          class="flex justify-center mt-8 mb-8"
        >
          <AddBanner class="cursor-pointer" @click="addMobileBannerItem"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import partnerAPI from '@/service/partnerAPI';
import RemoveIcon from '@/components/icons/RemoveIcon.vue';
import CircleXIcon from '@/components/icons/CircleXIcon.vue';
import IconBase from '@/components/icons/IconBase.vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';
import AddBanner from '@/components/icons/AddBanner.vue';
import PictureIcon from '@/views/layouts/AppLayout/icons/PictureIcon.vue';
import getServerErrorMessage from '@/utils/getServerErrorMessage';

export default defineComponent({
  name: 'Banner',
  components: {
    IconBase,
    CheckCircleIcon,
    PictureIcon,
    AddBanner,
    RemoveIcon,
    CircleXIcon
  },
  setup() {
    const mainBannerData = ref([])
    const subBannerData = ref([])
    const mobileBannerData = ref([])

    const addMainBannerItem = () => {
      (mainBannerData.value as any).push({})
    }
    const addMobileBannerItem = () => {
      (mobileBannerData.value as any).push({})
    }
    const removeMobileBannerItem = (index) => {
      (mobileBannerData.value as any).splice(index, 1)
    }
    const removeMainBannerItem = (index) => {
      (mainBannerData.value as any).splice(index, 1)
    }
    const handleChangeImageInput = async (type, index, e) => {
      if (e.target.files) {
        await uploadImageFiles(type, index, e.target.files);
      }
    };

    const uploadImageFiles = async (type, index, files: FileList) => {
      try {
        const { data } = await partnerAPI.adminFile.adminFileUpload({
          folderPath: 'banner', file: files[0]
        });
        if (type === 'main') {
          (mainBannerData.value[index] as any).imageUrl = data
        }
        if (type === 'sub') {
          (subBannerData.value[index] as any).imageUrl = data
        }
        if (type === 'mobile') {
          (mobileBannerData.value[index] as any).imageUrl = data
        }
      } catch (e) {
        alert(getServerErrorMessage(e));
      }
    };

    const handleRegister = async (type, banner) => {
      banner.type = type

      if (!banner.bannerId && type === 'main') {
        banner.order = Math.max(...(mainBannerData.value as any).map(banner => banner.order || 0)) + 1 || 0
      }
      if (!banner.bannerId && type === 'mobile') {
        banner.order = Math.max(...(mobileBannerData.value as any).map(banner => banner.order)) + 1 || 0
      }
      console.log('type', type)
      console.log('banner', banner)

      try {
        const { data } = await partnerAPI.adminBanner.adminBannerCreateUpdate(banner)
        alert(data.message)
        fetchBanner()
      } catch (e) {
        alert(getServerErrorMessage(e));
      }
    }

    const handlerDelete = async (bannerId) => {
      if (confirm('삭제 하시겠습니까?')) {
        try {
          const { data } = await partnerAPI.adminBanner.adminBannerDelete({
            bannerId
          });
          console.log(data)
          alert(data.message)
          fetchBanner()
        } catch (e) {
          alert(getServerErrorMessage(e));
        }
      }
    }

    onMounted(() => {
      fetchBanner()
    })

    const fetchBanner = async () => {
      try {
        const { data } = await partnerAPI.adminBanner.adminBannerList();

        mainBannerData.value = (data as any).data.filter((item) => item.type === 'main')
        subBannerData.value = (data as any).data.filter((item) => item.type === 'sub')
        mobileBannerData.value = (data as any).data.filter((item) => item.type === 'mobile')
      } catch (e) {
        alert(getServerErrorMessage(e));
      }
    };
    return {
      handleRegister,
      handlerDelete,
      handleChangeImageInput,
      addMainBannerItem,
      removeMainBannerItem,
      addMobileBannerItem,
      removeMobileBannerItem,
      subBannerData,
      mobileBannerData,
      mainBannerData,
    };
  },
});
</script>

<style lang="scss" scoped>
.banner-area {
  background: #fff;
}
.admin-input {
  &:focus {
    outline: none;
    border: 1px solid #49CDDA;
  }
}
.banner-notice-list {
  li {
    &:before {
      content: '';
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: #888;
      margin-right: 8px;
      margin-left: 10px;
    }
  }
}
.wide-banner-text {
  width: calc(100% - 960px);
}
.hover-banner-text {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
.drag-container:hover {
  .hover-banner-text {
    display: flex;
  }
}
.banner-image {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100%;
}
.sub-admin-button {
  width: 160px;
}
</style>
