
import { defineComponent, onMounted, ref } from 'vue';
import partnerAPI from '@/service/partnerAPI';
import RemoveIcon from '@/components/icons/RemoveIcon.vue';
import CircleXIcon from '@/components/icons/CircleXIcon.vue';
import IconBase from '@/components/icons/IconBase.vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';
import AddBanner from '@/components/icons/AddBanner.vue';
import PictureIcon from '@/views/layouts/AppLayout/icons/PictureIcon.vue';
import getServerErrorMessage from '@/utils/getServerErrorMessage';

export default defineComponent({
  name: 'Banner',
  components: {
    IconBase,
    CheckCircleIcon,
    PictureIcon,
    AddBanner,
    RemoveIcon,
    CircleXIcon
  },
  setup() {
    const mainBannerData = ref([])
    const subBannerData = ref([])
    const mobileBannerData = ref([])

    const addMainBannerItem = () => {
      (mainBannerData.value as any).push({})
    }
    const addMobileBannerItem = () => {
      (mobileBannerData.value as any).push({})
    }
    const removeMobileBannerItem = (index) => {
      (mobileBannerData.value as any).splice(index, 1)
    }
    const removeMainBannerItem = (index) => {
      (mainBannerData.value as any).splice(index, 1)
    }
    const handleChangeImageInput = async (type, index, e) => {
      if (e.target.files) {
        await uploadImageFiles(type, index, e.target.files);
      }
    };

    const uploadImageFiles = async (type, index, files: FileList) => {
      try {
        const { data } = await partnerAPI.adminFile.adminFileUpload({
          folderPath: 'banner', file: files[0]
        });
        if (type === 'main') {
          (mainBannerData.value[index] as any).imageUrl = data
        }
        if (type === 'sub') {
          (subBannerData.value[index] as any).imageUrl = data
        }
        if (type === 'mobile') {
          (mobileBannerData.value[index] as any).imageUrl = data
        }
      } catch (e) {
        alert(getServerErrorMessage(e));
      }
    };

    const handleRegister = async (type, banner) => {
      banner.type = type

      if (!banner.bannerId && type === 'main') {
        banner.order = Math.max(...(mainBannerData.value as any).map(banner => banner.order || 0)) + 1 || 0
      }
      if (!banner.bannerId && type === 'mobile') {
        banner.order = Math.max(...(mobileBannerData.value as any).map(banner => banner.order)) + 1 || 0
      }
      console.log('type', type)
      console.log('banner', banner)

      try {
        const { data } = await partnerAPI.adminBanner.adminBannerCreateUpdate(banner)
        alert(data.message)
        fetchBanner()
      } catch (e) {
        alert(getServerErrorMessage(e));
      }
    }

    const handlerDelete = async (bannerId) => {
      if (confirm('삭제 하시겠습니까?')) {
        try {
          const { data } = await partnerAPI.adminBanner.adminBannerDelete({
            bannerId
          });
          console.log(data)
          alert(data.message)
          fetchBanner()
        } catch (e) {
          alert(getServerErrorMessage(e));
        }
      }
    }

    onMounted(() => {
      fetchBanner()
    })

    const fetchBanner = async () => {
      try {
        const { data } = await partnerAPI.adminBanner.adminBannerList();

        mainBannerData.value = (data as any).data.filter((item) => item.type === 'main')
        subBannerData.value = (data as any).data.filter((item) => item.type === 'sub')
        mobileBannerData.value = (data as any).data.filter((item) => item.type === 'mobile')
      } catch (e) {
        alert(getServerErrorMessage(e));
      }
    };
    return {
      handleRegister,
      handlerDelete,
      handleChangeImageInput,
      addMainBannerItem,
      removeMainBannerItem,
      addMobileBannerItem,
      removeMobileBannerItem,
      subBannerData,
      mobileBannerData,
      mainBannerData,
    };
  },
});
